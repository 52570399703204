import { Box, Button, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { getUrlProps } from "helpers/landing"

import Image from "./image"

export const Container = styled(Box).attrs((props) => ({
  display: "grid",
  gridGap: "6px",
  gridTemplateColumns: {
    _: "repeat(2, 1fr)",
    tablet: "repeat(4, 1fr)",
  },
  px: { _: "6px", tablet: "10px" },
  py: { _: "6px", tablet: "12px" },
  ...props,
}))``

const ItemContainer = styled(Flex).attrs((props) => ({
  bg: "blackPrint",
  bottom: 0,
  color: "white",
  flexDirection: "column",
  height: "auto",
  justifyContent: "flex-end",
  left: 0,
  position: "absolute",
  px: "12px",
  py: { _: 1, desktop: "12px" },
  width: "100%",
  ...props,
}))``

const Title = styled(Typography).attrs((props) => ({
  textTransform: "uppercase",
  ...props,
}))``

const Subtitle = styled(Typography).attrs((props) => ({
  display: { _: "none", tablet: "block" },
  ...props,
}))``

const CTABox = styled(Box).attrs((props) => ({
  fontSize: { _: "16px", desktop: "18px" },
  fontWeight: "600",
  lineHeight: "22px",
  ...props,
}))``

const CTA = styled(Button).attrs({
  variant: "text",
})`
  text-decoration: underline;
`

const ImageLink = styled(Button).attrs({
  variant: "text",
})`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;

  &:focus ~ ${ItemContainer}, &:hover ~ ${ItemContainer} {
    ${CTA} {
      text-decoration: none;
    }
  }
`

const FeatureGrid = ({ element, lazyload }) => {
  return (
    <Box {...element.style?.block_style}>
      <Container containWide={element.contain} {...element.container_style}>
        {(element.data?.items || []).map((item, index) => {
          const urlProps = getUrlProps(
            item.url,
            element.anchor_name || element.id
          )

          return (
            <Box
              key={`${element.id}-${index}`}
              position="relative"
              {...item.style?.block_style}
            >
              {item.url && (
                <ImageLink
                  aria-label={item.image?.alt || item.title}
                  {...urlProps}
                />
              )}
              <Image image={item.image} lazyload={lazyload} />
              {(item.title || item.subtitle || (item.cta && item.url)) && (
                <ItemContainer {...item.style?.container_style}>
                  {item.title && (
                    <Title
                      as="h2"
                      {...item.style?.title_style}
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      variant="h3"
                    />
                  )}
                  {item.subtitle && (
                    <Subtitle
                      {...item.style?.subtitle_style}
                      dangerouslySetInnerHTML={{ __html: item.subtitle }}
                      variant="body"
                    />
                  )}
                  {item.cta && item.url && (
                    <CTABox {...item.style?.cta_style}>
                      <CTA aria-hidden tabIndex="-1" {...urlProps}>
                        {item.cta}
                      </CTA>
                    </CTABox>
                  )}
                </ItemContainer>
              )}
            </Box>
          )
        })}
      </Container>
    </Box>
  )
}

FeatureGrid.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default FeatureGrid
