import { Box, Shimmer } from "@jewlr/storybook/core"
import React from "react"

const TextareaSkeleton = () => (
  <Box
    maxWidth="1440px"
    mb={3}
    mt={{ _: 3, desktop: 0 }}
    mx="auto"
    pt={{ _: 3, desktop: 5 }}
    textAlign="center"
  >
    <Shimmer height={{ _: "360px", tablet: "500px" }} mb={3} />
    <Shimmer
      height={{ _: "24px", tablet: "40px" }}
      mb={{ _: 1, tablet: 3 }}
      mx="auto"
      width={{ _: "75%", tablet: "50%" }}
    />
    <Shimmer height="44px" mx="auto" width="220px" />
  </Box>
)

export default TextareaSkeleton
