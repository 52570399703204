import { Image } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled, { keyframes } from "styled-components"

import ItemContainer from "./item-container"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const ImageSkeleton = styled(Image)`
  animation: ${shimmer} 1.5s forwards infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: auto;
  max-width: 100%;
  overflow: hidden;
  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    max-width: 256px;
  `}
  ${({ theme }) => theme.mediaQueries.widescreen`
    max-width: 300px;
  `}
`

const Placeholder = ({ dataCy, index }) => (
  <ItemContainer data-cy={dataCy}>
    <ImageSkeleton
      alt=""
      ratio="auto 1 / 1"
      src={require(`images/collections/placeholder_masks/ring_${
        (index % 6) + 1
      }.svg`)}
    />
  </ItemContainer>
)

Placeholder.propTypes = {
  dataCy: PropTypes.string,
  index: PropTypes.number,
}

export default Placeholder
