/**
 * Check if value passes filter validation
 *
 * @param {Object} filter
 * @param {Object} property
 */
function filterCheck(filter, property) {
  const selectedValue =
    (filter.key ? property[filter.key] : property.value) || ""

  /**
   * In case if filter does not have 'type' property it means that this is just a string values
   */
  if (filter && filter.type) {
    switch (filter.type) {
      case "exact":
        return filter.value === selectedValue
      case "regex":
        return RegExp(filter.value).test(selectedValue)
    }
  }
  return filter === selectedValue
}

/**
 * e.g.
 * 1. productOptions => [{
 *      ...
 *      filters: {
 *          ...
 *          sku: [] <- selectionProperties.pref = 'sku'
 *          ...
 *      }
 * }]
 * 2. Look for ProductOption that matches filter
 *
 *
 * selectionProperties eg {pref: 'sku', value: '14KW'}
 * pref defines which filter property we use,
 * value defines what we are looking for
 *
 * @param {Array} productOptions Array of ProductOption
 * @param {Object} selectionProperties Object defines selection
 * @param {Boolean} defaultIfNoFilters if option has no filters property default
 * @returns {Array} filteredProductOptions
 */
export function filterProductOptions(
  productOptions,
  selectionProperties,
  defaultIfNoFilters = false
) {
  let filteredProductOptions = productOptions.filter((productOption) => {
    let match = true

    if (
      productOption.filters &&
      productOption.filters[selectionProperties.pref]
    ) {
      let filterOptions = productOption.filters[selectionProperties.pref]
      match = filterMatch(filterOptions, selectionProperties)
    } else if (productOption.filters) {
      Object.keys(productOption.filters).every((pref) => {
        if (selectionProperties[pref]) {
          let filterOptions = productOption.filters[pref]

          match = filterMatch(filterOptions, selectionProperties[pref])
        } else {
          match = defaultIfNoFilters
        }
        return match
      })
    } else {
      match = defaultIfNoFilters
    }
    return match
  })
  return filteredProductOptions
}

function filterMatch(options, property) {
  let index = 0
  let match = false
  while (index < options.length && !match) {
    const filter = options[index]
    match = filterCheck(filter, property)
    ++index
  }
  return match
}

export function filterProductOptionsV2(
  productOptions,
  choices = {},
  defaultIfNoFilters = false
) {
  return productOptions.filter((productOption) => {
    if (!Object.keys(choices).length) return defaultIfNoFilters

    let match = true
    Object.keys(productOption.filters).map((key) => {
      let match2 = true
      Object.entries(choices).forEach(([pref, property]) => {
        if (
          pref === key &&
          !filterMatch(productOption.filters[key], property)
        ) {
          match2 = false
        }
      })
      if (!match2) match = false
    })
    return match
  })
}

/**
 * Check if metal is in array
 * @param {array} metalFilters
 * @param {Object} metal
 */
export function checkMetalExists(metalFilters, metal) {
  let found = false
  if (metalFilters) {
    metalFilters.forEach((metalFilter) => {
      if (filterCheck(metalFilter, metal)) {
        found = true
      }
    })
  } else {
    return true
  }
  return found
}

export function findMatchesInFilters(filters1 = {}, filters2 = {}, pref) {
  const prefFilters1 = filters1[pref] || []
  const prefFilters2 = filters2[pref] || []

  return prefFilters1.some((filter1Value) =>
    prefFilters2.some((filter2Value) => filterCheck(filter1Value, filter2Value))
  )
}

export function getSelectedMetalFilter(choices) {
  if (typeof choices.sku === "undefined" && typeof choices.m2 === "undefined") {
    return ""
  } else {
    // If m2 is defined, assume that is the metal filter trigger
    return {
      pref: typeof choices.m2 === "undefined" ? "sku" : "m2",
      value:
        typeof choices.m2 === "undefined"
          ? choices.sku.value
          : choices.m2.value,
    }
  }
}
