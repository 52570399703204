import { Box, Flex, Typography } from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { Suspense, useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import useCollection from "areas/collection/hoc/use-collection"
import withWishlist from "areas/collection/hoc/with-wishlist"

import CollectionAd from "./collection-ad"
import CollectionProduct from "./collection-product"
import Placeholder from "./placeholder"

const Container = styled(Flex).attrs((props) => ({
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
  ...props,
}))``

export const GridContainer = styled(Box)`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr;
  ${(props) => props.theme.mediaQueries.tablet`
    grid-template-columns: 1fr 1fr 1fr;
  `}
  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const InternalCollectionMain = ({
  addToWishlist,
  confirmAddToWishlist,
  newPageFocusRef,
}) => {
  const [showWishlistDialog, setShowWishlistDialog] = useState(false)
  const [productParams, setProductParams] = useState(false)
  const loading = useSelector((state) => state.collection.loading)
  const {
    data: {
      pagination: {
        next_page_product_count: nextPageCount,
        results_per_page: resultsPerPage = 24,
      } = {},
      placeholders = 0,
      product_result: products = [],
    } = {},
    isFetchingNextPage,
  } = useCollection()

  if (!loading && products?.length === 0) {
    return (
      <Container data-cy="collection-not-result">
        <Typography fontSize="20px" my="2em" textAlign="center">
          Sorry, we could not find any results for your search. Please try a
          different search term.
        </Typography>
      </Container>
    )
  }

  return (
    <>
      <GridContainer data-cy="collection-main-products">
        {loading ? (
          resultsPerPage > 0 &&
          [...Array(resultsPerPage)].map((_, i) => (
            <Placeholder
              dataCy={`collection-loading-placeholder-${i}`}
              index={i}
              key={`loading-placeholder-${i}`}
            />
          ))
        ) : (
          <>
            {placeholders > 0 &&
              [...Array(placeholders)].map((_, i) => (
                <Placeholder
                  dataCy={`collection-loading-placeholder-${i}`}
                  index={i}
                  key={`collection-loading-placeholder-${i}`}
                />
              ))}
            {products.map((product, i) =>
              product.item_type === "ad_block" ? (
                <CollectionAd
                  ad={product}
                  index={i}
                  key={`ad-product-${product.style_code}-${i}`}
                  newPageFocusRef={newPageFocusRef}
                />
              ) : (
                <CollectionProduct
                  confirmWishlist={setShowWishlistDialog}
                  inCollection
                  index={i}
                  key={`product-${product.style_code}-${i}`}
                  newPageFocusRef={newPageFocusRef}
                  product={product}
                  setProductParams={setProductParams}
                />
              )
            )}
            {isFetchingNextPage &&
              [...Array(nextPageCount)].map((_, i) => (
                <Placeholder
                  dataCy={`collection-next-page-placeholder-${i}`}
                  index={i}
                  key={`loading-more-products-${i}`}
                />
              ))}
          </>
        )}
      </GridContainer>
      {typeof window !== "undefined" && (
        <Suspense fallback={<div />}>
          <Confirmation
            cancelText="Cancel"
            confirmText="Proceed"
            description="You have reached the maximum number of items on your wish list. Adding this item will remove the oldest from your list."
            onCancel={() => {
              confirmAddToWishlist(false)
              setShowWishlistDialog(false)
            }}
            onConfirm={() => {
              confirmAddToWishlist(true)
              setShowWishlistDialog(false)
              addToWishlist(productParams)
            }}
            show={showWishlistDialog}
            title="Add to Wish List"
            variant="squircle"
          />
        </Suspense>
      )}
    </>
  )
}

InternalCollectionMain.propTypes = {
  addToWishlist: PropTypes.func,
  confirmAddToWishlist: PropTypes.func,
  newPageFocusRef: PropTypes.object,
}

const CollectionMain = withWishlist(InternalCollectionMain)

export default CollectionMain
