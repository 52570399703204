// This function use for apply filters in collections page.
export default function applyFilters(existingFilters, applyFilter, belongsTo) {
  let filters = []
  let filtersActivationStatus = []

  filters = existingFilters.map((filter, filterIndex) => {
    const conditionalTags = Object.keys(filter.conditional_tags)
    let skipTags = []
    // let removingFilterWithConditionalTags

    if (conditionalTags.length > 0) {
      if (conditionalTags.includes(applyFilter[0])) {
        skipTags.push(...filter.conditional_tags[applyFilter[0]])
      } else {
        const flattenedConditionalTags = conditionalTags
          .map((t) => filter.conditional_tags[t])
          .flat()
        // TEMP: Daniel wants to add ability to select karat independent of metals
        // removingFilterWithConditionalTags = filter.categories.reduce(
        //   (pre, category) => {
        //     if (
        //       category.filter_param === applyFilter &&
        //       category.is_active_filter
        //     ) {
        //       return true
        //     }
        //     return pre
        //   },
        //   false
        // )
        if (
          // !removingFilterWithConditionalTags &&
          flattenedConditionalTags.includes(applyFilter[0])
        ) {
          skipTags = [
            ...skipTags,
            ...filter.categories
              .filter(
                (category) =>
                  category.is_active_filter == true &&
                  conditionalTags.includes(category.filter_tag)
              )
              .map((cat) => cat.filter_tag),
          ]
        }
      }
    }

    const categories = filter.categories.map((category) => {
      if (category.filter_param === applyFilter) {
        return {
          ...category,
          is_active_filter: !category.is_active_filter,
        }
      } else {
        if (
          belongsTo === "all" ||
          (!skipTags.includes(category.filter_tag) && belongsTo === filter.name)
          // On remove filters
          // (typeof belongsTo == "undefined" && removingFilterWithConditionalTags)
        ) {
          return {
            ...category,
            is_active_filter: false,
          }
        }
      }
      return category
    })

    const hasActiveFilter = categories.some((cat) => cat.is_active_filter)
    filtersActivationStatus[filterIndex] = hasActiveFilter

    return {
      ...filter,
      categories,
    }
  })

  // If the filter shouldn't affect all other filters and we have at least one active filter
  // Handle automatic filter functionality
  if (belongsTo != "all" && filtersActivationStatus.includes(true)) {
    filters.forEach((filter, filterIndex) => {
      // If filter is required and it hasn't have any active category
      if (
        filter.required === true &&
        !filtersActivationStatus[filterIndex] &&
        filter.categories[filter.default_category_index]
      ) {
        filter.categories[filter.default_category_index].is_active_filter = true
      }
    })
  }

  return filters
}
