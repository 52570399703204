import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import { createResponsiveStateReducer } from "redux-responsive"

import accountReducer from "areas/account/store/reducers"
import cartUiReducer from "areas/cart/store/cart-ui-reducer"
import cartReducer from "areas/cart/store/reducers"
import checkoutReducer from "areas/checkout/store/reducers"
import collectionReducer from "areas/collection/store/reducers"
import {
  boxDesigns,
  panel,
  choices,
  diamondSelector,
  diamondUI,
  previewImage,
  previewImageUI,
  wizard,
  wizardUI,
  display,
  halfModal,
} from "areas/product/store/reducers"

import {
  alerts,
  experiment,
  //   header,
  menu,
  messages,
  localeHM,
  sale,
  scrollMap,
  searchSuggestion,
  user,
} from "./app/reducers"
import headerReducer from "./header/reducers"
import searchReducer from "./search/reducers"
import shareReducer from "./share/reducers"
import wishlistReducer from "./wishlist/reducers"

function client(
  state = {
    fullLoading: false,
  },
  action
) {
  switch (action.type) {
    case "FULL_LOADING":
      return { ...state, fullLoading: action.loading }
    default:
      return state
  }
}

const app = (history) =>
  combineReducers({
    account: accountReducer,
    alerts,
    boxDesigns,
    browser: createResponsiveStateReducer({
      desktop: 9999,
      mobile: 767,
      tablet: 1023,
    }),
    cart: cartReducer,
    cartUI: cartUiReducer,
    checkout: checkoutReducer,
    choices,
    client,
    collection: collectionReducer,
    diamondSelector,
    diamondUI,
    display,
    experiment: experiment(history),
    halfModal,
    header: headerReducer,
    localeHM,
    menu,
    messages,
    panel,
    previewImage,
    previewImageUI,
    router: connectRouter(history),
    sale,
    scrollMap,
    search: searchReducer,
    searchSuggestion,
    share: shareReducer,
    user,
    wishlist: wishlistReducer,
    wizard,
    wizardUI,
  })

export default app
