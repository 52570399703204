/**
 * DO NOT MAKE CHANGES TO THIS FILE TO FIX STYLING ISSUES
 *
 * This file is meant for CSS Reset only.
 */

function normalize(props) {
  return `
    * {
      box-sizing: border-box;
    }
    *:focus {
      outline: 2px solid ${props.theme.colors.primary};
      outline-offset: 2px;
    }
    *:focus-visible {
      outline: 2px solid ${props.theme.colors.primary};
      outline-offset: 2px;
    }
    *:focus:not(:focus-visible) {
      outline: none;
    }
    html {
      font-size: 16px;
      line-height: 1.2;
      -webkit-text-size-adjust: 100%;
    }
    body {
      color: ${props.theme.colors.text};
      font-family: ${props.theme.fonts.main};
      margin: 0;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
      margin: 0;
      font-family: inherit;
      font-size: 100%;
      line-height: 1.2;
    }
    input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
      -webkit-appearance: none;
    }
    hr {
      height: 1px;
      border: none;
      background-color: lightgrey;
    }
    a {
      color: inherit;
      text-decoration: none;
      :hover {
        text-decoration: none;
      }
      :active {
        opacity: 0.6;
      }
    }
    figure {
      margin: 0;
    }
    img {
      vertical-align: middle;
    }
    small {
      font-size: 80%;
    }
    .nowrap {
      white-space: nowrap;
    }

    .js-shake {
      animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
    .pac-container {
      z-index: 2000;
    }
    .affirm-iframe-widget {
      display: none !important;
    }
  `
}
export default normalize
