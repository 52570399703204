import { Button } from "@jewlr/storybook/core"
import { Link } from "react-router-dom"

import scrollToElement from "./scroll-to-element"

export const getUrlProps = (url, linkClickData, onClick = null) => {
  if (!url) return {}

  if (url.startsWith("http")) {
    return {
      as: "a",
      "data-lc": linkClickData,
      display: "block",
      href: url,
      onClick: () => {
        if (onClick) {
          onClick()
        }
      },
    }
  } else if (url.startsWith("#")) {
    return {
      "data-lc": linkClickData,
      forwardedAs: Button,
      onClick: () => {
        if (onClick) {
          onClick()
        }
        scrollToElement(url)
      },
    }
  }

  const path = !url.startsWith("/") ? `/${url}` : url
  return {
    as: Link,
    "data-lc": linkClickData,
    display: "block",
    onClick: () => {
      if (onClick) {
        onClick()
      }
    },
    to: path,
  }
}
