import { Box, Picture } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

const GridItem = styled(Box)`
  margin-bottom: 24px;
  padding: 0 8px;
  ${({ theme }) => theme.mediaQueries.tablet`
    margin-bottom: 30px;
  `}
  ${(props) =>
    props.adsize > 1 &&
    `
    grid-column: span ${props.adsize};
  `}
`

const ImageWrapper = styled.div`
  width: 100%;
`

const StyledVideo = styled.video.attrs({
  className: "lazyload",
  "data-autoplay": "",
  loop: true,
  muted: true,
  playsInline: true,
  preload: "none",
})`
  height: 100% !important;
  width: 100% !important;
`

const CollectionAd = ({ ad, index, newPageFocusRef }) => {
  const newPageStartIndex = useSelector(
    (state) => state.collection.newPageStartIndex
  )

  if (ad.ad_type === "jpg" || ad.ad_type === "gif") {
    return (
      <GridItem
        adsize={ad.ad_size}
        as={ad.href && ad.href != "" ? Link : "div"}
        data-lc="collection-ad"
        ref={index === newPageStartIndex ? newPageFocusRef : undefined}
        to={ad.href}
      >
        <ImageWrapper>
          <Picture
            alt={ad.label || "ads"}
            srcSet={[
              {
                height: ad.use_new_ratio ? "450" : "380",
                media: "(max-width: 767px)",
                srcSet: `
                  ${ad[ad.ad_type].mobile.x1} 1x,
                  ${ad[ad.ad_type].mobile.x2} 2x,
                `,
                width: ad.ad_size === 1 ? "360" : "766",
              },
              {
                height: "288",
                media: "(min-width: 768px) and (max-width: 1023px)",
                srcSet: `
                  ${ad[ad.ad_type].tablet.x1} 1x,
                  ${ad[ad.ad_type].tablet.x2} 2x,
                `,
                width: ad.ad_size === 1 ? "232" : "464",
              },
              {
                height: ad.use_new_ratio ? "320" : "280",
                media: "(min-width: 1024px)",
                srcSet: `
                  ${ad[ad.ad_type].desktop.x1} 1x,
                  ${ad[ad.ad_type].desktop.x2} 2x,
                `,
                width: ad.ad_size === 1 ? "330" : "660",
              },
            ]}
          />
        </ImageWrapper>
      </GridItem>
    )
  } else if (ad.ad_type === "video") {
    return (
      <GridItem
        adsize={ad.ad_size}
        data-lc="collection-ad"
        ref={index === newPageStartIndex ? newPageFocusRef : undefined}
        {...(!!ad.href && {
          "aria-label": ad.label || "ads",
          as: Link,
          to: ad.href,
        })}
      >
        <Box position="relative" width="100%">
          <StyledVideo>
            {ad.webm && ad.webm != "" && (
              <source src={ad.webm} type="video/webm" />
            )}
            {ad.mp4 && ad.mp4 != "" && <source src={ad.mp4} type="video/mp4" />}
          </StyledVideo>
        </Box>
      </GridItem>
    )
  } else {
    return null
  }
}

CollectionAd.propTypes = {
  ad: PropTypes.object,
  index: PropTypes.number,
  newPageFocusRef: PropTypes.object,
}

export default CollectionAd
