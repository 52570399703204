import { Box, Shimmer } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"

import CollectionMenuSkeleton from "./collection-menu"
import FeatureGridSkeleton from "./feature-grid"
import TextareaSkeleton from "./textarea"

const LandingLoading = ({ partial }) => {
  return (
    <>
      {!partial && (
        <Box containWide>
          <Shimmer height="500px" />
        </Box>
      )}
      <CollectionMenuSkeleton />
      <TextareaSkeleton />
      <FeatureGridSkeleton />
      {!partial && (
        <>
          <CollectionMenuSkeleton />
          <TextareaSkeleton />
          <FeatureGridSkeleton />
        </>
      )}
      <Box containWide>
        <Shimmer height="300px" />
      </Box>
    </>
  )
}

LandingLoading.propTypes = {
  partial: PropTypes.bool,
}

export default LandingLoading
