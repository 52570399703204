import { faHeart as faHeartLight } from "@jewlr/pro-light-svg-icons/faHeart"
import { faSpinner } from "@jewlr/pro-regular-svg-icons/faSpinner"
import { faHeart as faHeartSolid } from "@jewlr/pro-solid-svg-icons/faHeart"
import { Button, FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

import withWishlist from "areas/collection/hoc/with-wishlist"

const IconButton = styled(Button).attrs({
  variant: "text",
})`
  position: absolute;
  right: 20px;
  top: 5px;
  z-index: 1;
`

const Icon = styled(FontAwesomeIcon).attrs((props) => ({
  color: props.$wishlistAdded
    ? props.theme.colors.wishlist
    : props.theme.colors.black,
  fontSize: "24px",
}))``

const WishlistIcon = ({
  confirmWishlist,
  setProductParams,
  toggleAddToWishlist,
  wishlistAdded,
  wishlistLoading,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!wishlistLoading) {
      setLoading(wishlistLoading)
    }
  }, [wishlistLoading])

  return (
    <IconButton
      aria-label={
        loading
          ? "Loading wish list"
          : wishlistAdded
          ? "Remove from wish list"
          : "Add to wish list"
      }
      disabled={loading}
      id="col_add_to_wishlist"
      onClick={(e) => {
        if (!loading) {
          setLoading(true)
          toggleAddToWishlist(confirmWishlist, setProductParams, e, setLoading)
        }
      }}
    >
      {loading ? (
        <FontAwesomeIcon fontSize="20px" icon={faSpinner} spin />
      ) : (
        <Icon
          $wishlistAdded={wishlistAdded}
          icon={wishlistAdded ? faHeartSolid : faHeartLight}
        />
      )}
    </IconButton>
  )
}

WishlistIcon.propTypes = {
  confirmWishlist: PropTypes.func,
  setProductParams: PropTypes.func,
  toggleAddToWishlist: PropTypes.func,
  wishlistAdded: PropTypes.bool,
  wishlistLoading: PropTypes.bool,
}

export default withWishlist(WishlistIcon)
