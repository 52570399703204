import React from "react"
import { useParams } from "react-router-dom"

import LandingLoading from "areas/landing/loaders"
import { IS_PORTAL } from "helpers/application"
import { checkCollectionUrl } from "helpers/collection/url-checker"

import Shimmers from "./shimmers"
import ShimmersPortal from "./shimmers-portal"

const CollectionFullLoading = () => {
  const { url_path } = useParams()

  if (url_path && checkCollectionUrl(url_path)) {
    return IS_PORTAL() ? <ShimmersPortal /> : <Shimmers />
  }

  return <LandingLoading />
}

export default CollectionFullLoading
