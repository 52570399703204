import { IS_PORTAL, jewlr_t, reverse_t } from "./application"
import getSpecialPaths from "./special-paths"

export const getHreflangs = (path) => {
  let defaultHref = ""
  const specialPaths = getSpecialPaths[path]
  let result = Object.entries(sl.config.site_url_by_country || {})
    .filter(([country]) => !specialPaths || specialPaths.includes(country))
    .map(([country, url]) => {
      const locale = (sl.locales || {})[country.toLowerCase()]
      const localizedPath = reverse_t(path, locale)
      const href = `${url}${localizedPath}`

      if (country === "US") {
        defaultHref = href
      }

      return {
        href,
        hreflang: locale,
      }
    })

  if (defaultHref) {
    result.push({
      href: defaultHref,
      hreflang: "x-default",
    })
  }

  return result
}

export const getMetaDescription = () =>
  `${IS_PORTAL() ? "Safyre Labs Portal" : "Jewlr"} ${jewlr_t(
    "terms.specialize"
  )}s in selling high quality ${jewlr_t("terms.personalized")} ${jewlr_t(
    "terms.jewelry"
  )}, made just for you. Create the perfect gift or ${jewlr_t(
    "terms.personalize"
  )} something for yourself. Enjoy Free ${jewlr_t(
    "terms.Shipping"
  )} and 99-Day Returns!`

export const getMetaKeywords = () =>
  `fine ${jewlr_t("terms.jewelry")}, ${jewlr_t("terms.jewelry")} ${jewlr_t(
    "terms.jewelry"
  )}, mothers rings, mothers ring, mother's ring, mother's rings, birthstone ${jewlr_t(
    "terms.jewelry"
  )} mothers birthstone ${jewlr_t("terms.jewelry")}, family ${jewlr_t(
    "terms.jewelry"
  )}`
