import { Box, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import Image from "components/landing/image"
import { getUrlProps } from "helpers/landing"
import { hideScrollbar } from "styles/utils"

export const Wrapper = styled(Flex).attrs((props) => ({
  containWide: true,
  justifyContent: { _: "flex-start", desktop: "center" },
  overflowX: "auto",
  pb: { _: "12px", desktop: 2 },
  pt: { _: 2, deskotp: 3 },
  px: { _: "6px", desktop: 0 },
  ...props,
}))`
  ${hideScrollbar}
`

export const Item = styled(Box).attrs((props) => ({
  flex: { _: "0 0 64px", desktop: "0 0 80px", widescreen: "0 0 100px" },
  mx: { _: "2px", desktop: "4px" },
  position: "relative",
  px: { _: 1, desktop: 2 },
  textAlign: "center",
  ...props,
}))``

export const defaultBubbleStyle = {
  borderRadius: "50%",
  height: { _: "64px", desktop: "80px", widescreen: "100px" },
  maxWidth: "auto",
  width: { _: "64px", desktop: "80px", widescreen: "100px" },
}

const ItemTitle = styled(Typography).attrs((props) => ({
  mt: { _: 1, widescreen: 2 },
  px: { tablet: "6px", widescreen: 2 },
  ...props,
}))``

const LandingCollectionMenu = ({ element, lazyload }) => {
  return (
    <Box {...element.style?.container_style}>
      <Wrapper {...element.style?.wrapper_style}>
        {element.items.map((item, item_index) => {
          const urlProps = getUrlProps(
            item.url,
            element.anchor_name || element.id
          )
          const image = {
            ...item.image,
            alt: "",
            style: {
              ...defaultBubbleStyle,
              ...item.image?.style,
            },
          }

          return (
            <Item
              key={`${element.anchor_name || element.id}-${item_index}`}
              {...element.style?.item_style}
            >
              <Box display="block" height="100%" {...urlProps}>
                <Image image={image} lazyload={lazyload} />
                <ItemTitle
                  variant="body-sm"
                  {...element.style?.item_title_style}
                  {...item.title_style}
                >
                  {item.title}
                </ItemTitle>
              </Box>
            </Item>
          )
        })}
      </Wrapper>
    </Box>
  )
}

LandingCollectionMenu.propTypes = {
  element: PropTypes.object,
  lazyload: PropTypes.bool,
}

export default LandingCollectionMenu
