import { Box, Flex, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { IS_JEWLR } from "helpers/application"
import { to_currency } from "helpers/number"

const Container = styled(Flex).attrs({
  alignItems: "center",
  justifyContent: "center",
})``

const StyledText = styled(Typography).attrs({
  color: "black",
  fontSize: "12px",
  lineHeight: "14px",
})``

const ProductPrice = ({ product }) => {
  const onSale = product.retail !== product.selling

  return (
    <Container>
      {false && product.quickShip && (
        <Box
          as="img"
          mr={1}
          src={require("images/icons/quick_ship_green.svg")}
          width="20px"
        />
      )}
      <StyledText>
        {IS_JEWLR() ? (
          <>
            from{" "}
            {onSale ? (
              <>
                (
                <strike>
                  {to_currency(product.retail, {
                    precision: 0,
                  })}
                </strike>
                ){" "}
                <Box
                  as="span"
                  color="sale"
                  data-cy={`collection-product-price-${product.style_code}`}
                >
                  {to_currency(product.selling, { precision: 0 })}
                </Box>
              </>
            ) : (
              to_currency(product.retail, {
                precision: 0,
              })
            )}
          </>
        ) : (
          <Box
            as="span"
            color="sale"
            data-cy={`collection-product-price-${product.style_code}`}
          >
            {to_currency(product.selling, { precision: 0 })}
          </Box>
        )}
      </StyledText>
    </Container>
  )
}

ProductPrice.propTypes = {
  product: PropTypes.object,
}

export default ProductPrice
