import { push } from "connected-react-router"
import Cookies from "js-cookie"
import { put, select } from "redux-saga/effects"
import URI from "urijs"

import { setUserOnCheckoutLogin } from "areas/checkout/store/actions"
import { closeModal } from "components/modal"
import {
  sendUserData,
  setLocalStorage,
  isTestMode,
  checkJewlrCustomer,
  setJewlrCustomerCookie,
} from "helpers/application"

import {
  activateAccountMenu,
  clearLoginCallback,
  setHeaderDropdown,
  setFacebookLoginState,
} from "../actions"

export default function* doLogin(action) {
  const state = yield select()
  const provider = action.loginType
  const userObj = action.loginObj
  const remember_me = action.loginRemember
  const query = URI.parseQuery(state.router.location.search)

  let cookie_options = {}
  if (provider == "facebook") {
    cookie_options = { expires: 30, secure: true }
  } else if (remember_me) {
    cookie_options = { expires: 365, secure: true }
  }

  const user = userObj.jewlrorders.user

  // Set user_obj to local storage  expiration
  setLocalStorage(user, "user_obj", cookie_options.expires)
  // Cookies.set uses SESSION cookies by default, we want this in the scenario of a login
  Cookies.set(
    sl.config.cookies_config.wishlist_token,
    userObj.wishlist_token,
    cookie_options
  )
  Cookies.set(
    sl.config.cookies_config.cart_token,
    userObj.cart_token,
    cookie_options
  )
  // if (typeof FS !== 'undefined') {
  //   FS.identify(userObj.jewlrorders.user.guid_token, {
  //     displayName: userObj.jewlrorders.first_name + ' ' + userObj.jewlrorders.last_name,
  //     email: userObj.jewlrorders.email
  //     // TODO: Add more vars here
  //   });
  // }
  // Update the userPreferences cookie if the user has a name
  var user_prefs = Cookies.getJSON("userPreferences")
  if (!user_prefs) {
    user_prefs = {}
  }
  if (userObj.jewlrorders.user.first_name) {
    if (
      userObj.jewlrorders.user.gender &&
      userObj.jewlrorders.user.gender == "M"
    ) {
      if (!user_prefs.e1) {
        user_prefs.e1 = userObj.jewlrorders.user.first_name
        Cookies.set("userPreferences", user_prefs, { expires: 2, secure: true })
      }
    } else {
      if (!user_prefs.e2) {
        user_prefs.e2 = userObj.jewlrorders.user.first_name
        Cookies.set("userPreferences", user_prefs, { expires: 2, secure: true })
      }
    }
  }
  if (typeof LC_API !== "undefined") {
    LC_API.set_visitor_name(
      `${userObj.jewlrorders.user.first_name || ""}
         ${userObj.jewlrorders.user.last_name || ""}`
    )
    LC_API.set_visitor_email(`${userObj.jewlrorders.user.email || ""}`)
  } else if (typeof window.__lc !== "undefined") {
    window.__lc.visitor = {
      email: userObj.jewlrorders.user.email,
      name:
        userObj.jewlrorders.user.first_name +
        " " +
        userObj.jewlrorders.user.last_name,
    }
  }
  if (typeof FS !== "undefined") {
    FS.identify(userObj.jewlrorders.user.guid_token, {
      displayName:
        userObj.jewlrorders.user.first_name +
        " " +
        userObj.jewlrorders.user.last_name,
      email: userObj.jewlrorders.user.email,
      // TODO: Add your own custom user variables here.
    })
  }
  // updateCartCountNew();
  // updateWishListCountNew();
  // Clear the wishlist and cart contents so a fresh call is made if previously cached
  // $('#js_wishlist_dropdown, .js-cart-dropdown').html('');

  if (state.checkout.form.checkoutLogin) {
    let data = {}
    if (userObj.order) {
      data.order = userObj.order
    }

    yield put(setUserOnCheckoutLogin(user, user.email, data))
  }

  const { pathname } = state.router.location
  const reload =
    pathname == "/wishlist" || pathname == "/cart" || pathname == "/signin"
  if (pathname === "/checkout/complete") {
    // Redirect to My VIP Rewards page if user is on Thank You page
    yield put(push("/account/points"))
  } else if (state.user.explicitLogin) {
    closeModal()
    if (!state.checkout.form.checkoutLogin && !reload) {
      yield put(activateAccountMenu())
      yield put(setHeaderDropdown("account"))
    }
  } else if (query.ref && query.ref != "") {
    yield put(push("/"))
    if (!reload) {
      yield put(activateAccountMenu())
      yield put(setHeaderDropdown("account"))
    }
  }

  // Reload the page if the user is on their Wish List or Cart page or the Sign In page
  if (reload) {
    window.location.reload()
  }

  // Perform any callback in the store
  if (state.user.callback) {
    state.user.callback()
    yield put(clearLoginCallback())
  }

  // Check if login was using Facebook
  if (state.user.facebook.loginState === "connecting") {
    yield put(setFacebookLoginState("success"))
  }
  // // Call the (window-global) sendUserData to update cart counts
  sendUserData(userObj.jewlrorders)

  if (state.user && !isTestMode) {
    if (user.num_orders > 0) {
      setJewlrCustomerCookie()
    }

    dataLayer.push({
      event: "login",
      jewlrCustomer: checkJewlrCustomer(),
      loginMethod: provider,
    })
  }
}
