/* eslint-disable no-case-declarations */
/**
 * CAUTION: This reducer file is used between both app-store-v2 and collection-store
 * YOU SHOULD TAKE CARE TO MAKE SURE BOTH SIDES WORK WHEN REMOVING THINGS HERE
 */

import { combineReducers } from "redux"
import { createResponsiveStateReducer } from "redux-responsive"

import applyFilters from "helpers/collection/applyFilters"

import {
  CLEAR_CATEGORY_FILTERS,
  CLEAR_COLLECTION,
  SET_COLLECTION,
  SET_LOADING,
  SET_SSR_NOT_FOUND,
  TOGGLE_FILTER_MENU,
  TOGGLE_SORT_MENU,
  UPDATE_CURRENT_SORT,
  UPDATE_FILTER,
  UPDATE_NEW_PAGE_START_INDEX,
  UPDATE_UNIQUE_FILTER,
} from "./actions"

const initialState = {
  filters: [],
  loading: "",
  newPageStartIndex: -1,
  open_filter_menu: false,
  open_sort_menu: false,
  preserve_cat_path: "",
  sort: "featured",
}

function collection(state = initialState, action) {
  let newState = Object.assign({}, state)

  switch (action.type) {
    case CLEAR_CATEGORY_FILTERS:
      newState.filters = state.filters.map((filter) => {
        const categories = filter.categories.map((category) => ({
          ...category,
          ...((action.category === "all" ||
            action.category === filter.name) && { is_active_filter: false }),
        }))

        return {
          ...filter,
          categories,
        }
      })
      return newState
    case CLEAR_COLLECTION:
      // We do not want to overwrite `ssr_not_found_path`
      newState = {
        ...newState,
        ...initialState,
      }
      return newState
    case SET_COLLECTION:
      newState.filters = action.data?.filter_menu
        ? action.data.filter_menu.all_collection_filters
        : []
      newState.preserve_cat_path = action.data?.preserve_cat_path
      newState.sort =
        action.data?.sort_menu?.current_sort !==
        action.data?.sort_menu?.default_sort
          ? action.data?.sort_menu?.current_sort
          : null
      return newState
    case SET_LOADING:
      newState.loading = action.loading
      return newState
    case SET_SSR_NOT_FOUND:
      newState.ssr_not_found_path = action.path
      return newState
    case TOGGLE_FILTER_MENU:
      newState.open_filter_menu = action.open
      return newState
    case TOGGLE_SORT_MENU:
      newState.open_sort_menu = action.open
      return newState
    case UPDATE_CURRENT_SORT:
      newState.sort = action.sort
      return newState
    case UPDATE_FILTER:
      newState.filters = applyFilters(
        state.filters,
        action.filter,
        action.belongs_to
      )
      return newState
    case UPDATE_NEW_PAGE_START_INDEX:
      newState.newPageStartIndex = action.index
      return newState
    case UPDATE_UNIQUE_FILTER:
      newState.filters = applyFilters(
        state.filters,
        action.param.filter,
        action.param.belongs_to
      )
      return newState
    default:
      return state
  }
}

const collectionReducer = combineReducers({
  browser: createResponsiveStateReducer({
    desktop: 9999,
    mobile: 767,
    tablet: 1023,
  }),
  collection,
})

export { collectionReducer }

export default collection
