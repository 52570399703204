export const SET_COLLECTION = "SET_COLLECTION"
export const SET_LOADING = "SET_LOADING"
export const SET_SSR_NOT_FOUND = "SET_SSR_NOT_FOUND"
export const CLEAR_CATEGORY_FILTERS = "CLEAR_CATEGORY_FILTERS"
export const CLEAR_COLLECTION = "CLEAR_COLLECTION"
export const TOGGLE_FILTER_MENU = "TOGGLE_FILTER_MENU"
export const TOGGLE_SORT_MENU = "TOGGLE_SORT_MENU"
export const UPDATE_CURRENT_SORT = "UPDATE_CURRENT_SORT"
export const UPDATE_FILTER = "UPDATE_FILTER"
export const UPDATE_NEW_PAGE_START_INDEX = "UPDATE_NEW_PAGE_START_INDEX"
export const UPDATE_UNIQUE_FILTER = "UPDATE_UNIQUE_FILTER"

// Wishlist
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const CONFIRM_ADD_TO_WISHLIST = "CONFIRM_ADD_TO_WISHLIST"
export const UNSELECT_FROM_WISHLIST = "UNSELECT_FROM_WISHLIST"

export function addToWishlist(style_code, selectedProduct, source) {
  return { selectedProduct, source, style_code, type: ADD_TO_WISHLIST }
}

export function confirmAddToWishlist(toggle) {
  return { toggle, type: CONFIRM_ADD_TO_WISHLIST }
}

export function unselectFromWishlist(style_code, source) {
  return { source, style_code, type: UNSELECT_FROM_WISHLIST }
}

export function clearCategoryFilters(category) {
  return { category, type: CLEAR_CATEGORY_FILTERS }
}

export function clearCollection() {
  return { type: CLEAR_COLLECTION }
}

export function setCollection(data) {
  return { data, type: SET_COLLECTION }
}

export function setLoading(loading) {
  return { loading, type: SET_LOADING }
}

export function setSSRNotFound(path) {
  return { path, type: SET_SSR_NOT_FOUND }
}

export function toggleFilterMenu(open) {
  return { open, type: TOGGLE_FILTER_MENU }
}

export function toggleSortMenu(open) {
  return { open, type: TOGGLE_SORT_MENU }
}

export function updateCurrentSort(sort) {
  return { sort, type: UPDATE_CURRENT_SORT }
}

export function updateFilter(filter) {
  return { filter, type: UPDATE_FILTER }
}

export function updateNewPageStartIndex(index) {
  return { index, type: UPDATE_NEW_PAGE_START_INDEX }
}

export function updateUniqueFilter(param) {
  return { param, type: UPDATE_UNIQUE_FILTER }
}
