import { Box } from "@jewlr/storybook/core"
import styled from "styled-components"

const Badge = styled(Box).attrs((props) => ({
  as: "span",
  borderRadius: "10px",
  color: "black",
  fontSize: "12px",
  left: "20px",
  lineHeight: "14px",
  px: 1,
  py: "2px",
  top: "5px",
  ...props,
}))`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  z-index: 1;
`

export default Badge
