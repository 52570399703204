import { all, takeLatest } from "redux-saga/effects"

import {
  ADD_TO_WISHLIST,
  CLEAR_CATEGORY_FILTERS,
  UNSELECT_FROM_WISHLIST,
  UPDATE_CURRENT_SORT,
  UPDATE_FILTER,
  UPDATE_UNIQUE_FILTER,
} from "./actions"
import addWishlist from "./sagas/add-wishlist"
import removeWishlist from "./sagas/remove-wishlist"
import updateFilter from "./sagas/update-filter"

function* rootSaga() {
  yield all([
    takeLatest(ADD_TO_WISHLIST, addWishlist),
    takeLatest(UNSELECT_FROM_WISHLIST, removeWishlist),
    takeLatest(
      [
        CLEAR_CATEGORY_FILTERS,
        UPDATE_CURRENT_SORT,
        UPDATE_FILTER,
        UPDATE_UNIQUE_FILTER,
      ],
      updateFilter
    ),
  ])
}

export default rootSaga
