import React from "react"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"

import { IS_PORTAL, jewlr_t } from "helpers/application"
import { getHreflangs, getMetaDescription, getMetaKeywords } from "helpers/meta"

const MetaTags = () => {
  const location = useLocation()
  const hreflangs = getHreflangs(
    `${location.pathname}${location.search}${location.hash}`
  )
  const siteLogo = IS_PORTAL()
    ? "portal/safyre_labs.jpg"
    : "jewlr/jewlr-logo.jpg"

  return (
    <Helmet
      defaultTitle={`${jewlr_t("terms.Personalized")} ${jewlr_t(
        "terms.Jewelry"
      )} | Custom Gemstones & Engravings`}
      encodeSpecialCharacters={false}
      prioritizeSeoTags
      titleTemplate={`%s | ${IS_PORTAL() ? "Safyre Labs Portal" : "Jewlr"}`}
    >
      <meta content="max-image-preview:large" name="robots" />
      {IS_PORTAL() && <meta content="noindex, nofollow" name="robots" />}
      <meta content={getMetaDescription()} name="description" />
      <meta content={getMetaDescription()} property="og:description" />
      <meta content={getMetaKeywords()} name="keywords" />
      <meta content="website" property="og:type" />
      <meta content={sl.config.site_name} property="og:site_name" />
      <meta
        content={`${sl.config.jewlr_asset_url}favicons/${siteLogo}`}
        name="og_image"
        property="og:image"
      />
      <meta
        content={`https://${sl.config.site_url}`}
        id="org_url"
        property="og:url"
      />
      <link
        href={`https://${sl.config.site_url}${location.pathname}`}
        rel="canonical"
      />
      {hreflangs.map(({ href, hreflang }) => (
        <link href={href} hrefLang={hreflang} key={hreflang} rel="alternate" />
      ))}
    </Helmet>
  )
}

export default MetaTags
