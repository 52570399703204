import { Shimmer } from "@jewlr/storybook/core"
import React from "react"

import { Container } from "components/landing/feature-grid"

const FeatureGridSkeleton = () => (
  <Container containWide>
    {[...Array(4)].map((_, index) => (
      <Shimmer
        height={{ _: "300px", tablet: "500px" }}
        key={`feature-grid-skeleton-${index}`}
      />
    ))}
  </Container>
)

export default FeatureGridSkeleton
