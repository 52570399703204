import { replace } from "connected-react-router"
import { put, select } from "redux-saga/effects"

export default function* updateFilter() {
  const queryParams = new URLSearchParams(location.search)
  const state = yield select()

  const activeFilters = state.collection.filters
    .reduce(
      (accu, filter) => [
        ...accu,
        ...filter.categories
          .filter((cat) => cat.is_active_filter)
          .reduce((params, cat) => [...params, ...cat.filter_param], []),
      ],
      []
    )
    .sort()
  const currentSort = state.collection.sort

  queryParams.delete("filtered_tags[]")
  queryParams.delete("new_sort")
  queryParams.delete("sort")

  if (activeFilters?.length > 0) {
    activeFilters.map((filter) => {
      queryParams.append("filtered_tags[]", filter)
    })
  }

  if (currentSort && currentSort !== "featured") {
    queryParams.set("sort", currentSort)
  }

  yield put(replace({ search: queryParams.toString() }))
}
