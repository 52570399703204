import { createSelector } from "@reduxjs/toolkit"
import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import {
  addToWishlist,
  confirmAddToWishlist,
  unselectFromWishlist,
} from "areas/collection/store/actions"

const selectWishlistProps = createSelector(
  [(state) => state.wishlist],
  (wishlist) => ({
    wishlistCount: wishlist.count,
    wishlistItems: wishlist.line_items,
    wishlistLoading: wishlist.loading,
  })
)

export default function withWishlist(WrappedComponent) {
  const WithWishlist = (props) => {
    const { product, selectedProductLook } = props

    const dispatch = useDispatch()
    const { wishlistCount, wishlistItems, wishlistLoading } = useSelector(
      selectWishlistProps
    )
    const wishlistAdded = useMemo(
      () =>
        (wishlistItems || []).some(
          (li) =>
            li.style_code === product?.style_code ||
            li.style_code === product?.rm_style_code
        ),
      [wishlistItems, product]
    )

    const handleAddToWishlist = (productParams) =>
      dispatch(
        addToWishlist(
          productParams.rm_style_code || productParams.style_code,
          productParams.selected_look,
          productParams.source
        )
      )

    const handleConfirmAddToWishlist = (toggle) =>
      dispatch(confirmAddToWishlist(toggle))

    const toggleAddToWishlist = useCallback(
      (confirmWishlistDialog, setProductParams, e, setLoading) => {
        if (wishlistLoading) return null
        e.preventDefault()
        e.stopPropagation()

        const styleCode = product.rm_style_code || product.style_code
        if (wishlistAdded) {
          dispatch(unselectFromWishlist(styleCode, "Collection"))
        } else if (wishlistCount >= sl.config.max_wishlist_items) {
          setLoading(false)
          dispatch(confirmAddToWishlist(false))
          if (setProductParams) {
            setProductParams({
              selected_look: selectedProductLook,
              source: "Collection",
              style_code: styleCode,
            })
          }
          confirmWishlistDialog(true)
        } else {
          dispatch(addToWishlist(styleCode, selectedProductLook, "Collection"))
        }
      },
      [
        wishlistLoading,
        product,
        wishlistAdded,
        wishlistCount,
        selectedProductLook,
      ]
    )

    return (
      <WrappedComponent
        {...{
          addToWishlist: handleAddToWishlist,
          confirmAddToWishlist: handleConfirmAddToWishlist,
          toggleAddToWishlist,
          wishlistAdded,
          wishlistCount,
          wishlistLoading,
        }}
        {...props}
      />
    )
  }

  WithWishlist.propTypes = {
    product: PropTypes.object,
    selectedProductLook: PropTypes.object,
  }

  return WithWishlist
}
