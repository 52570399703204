import { useInfiniteQuery } from "@tanstack/react-query"
import PropTypes from "prop-types"
import queryString from "query-string"
import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"

import api from "apis"

const ALLOWED_PARAMS = ["filtered_tags", "sort", "new_sort", "k"]

const useCollection = (ssrData) => {
  const { search } = useLocation()
  const params = useParams()
  const urlPath = params?.url_path || "homepage"

  const queryParams = search
    ? queryString.parse(search, { arrayFormat: "bracket" })
    : {}

  const catPath = useSelector((state) => state.collection.preserve_cat_path)
  const ssrNotFoundPath = useSelector(
    (state) => state.collection.ssr_not_found_path
  )
  const disabled = `/${urlPath}` === ssrNotFoundPath

  const paramKeys = useMemo(
    () =>
      Object.fromEntries(
        [
          ...Object.entries(queryParams).filter(([key]) =>
            ALLOWED_PARAMS.includes(key)
          ),
          ...Object.entries({
            cat_path: catPath,
          }),
        ].filter((entry) => !!entry[1])
      ),
    [queryParams, catPath]
  )

  const queryFn = useCallback(
    ({ pageParam = 1 }) =>
      api.fetch(
        `/api/v10/collections/${urlPath}/load_react_collection${
          Object.keys(paramKeys).length > 0 || pageParam > 1
            ? `?${queryString.stringify(
                { ...paramKeys, page: pageParam },
                { arrayFormat: "bracket" }
              )}`
            : ""
        }`
      ),
    [paramKeys, urlPath]
  )

  const queryData = useInfiniteQuery({
    enabled: !disabled,
    getNextPageParam: (lastPage) =>
      lastPage?.pagination?.current_page_number <
      lastPage?.pagination?.total_page_count
        ? lastPage?.pagination?.current_page_number + 1
        : undefined,
    getPreviousPageParam: (firstPage) =>
      firstPage?.pagination?.current_page_number > 1
        ? firstPage?.pagination?.current_page_number - 1
        : undefined,
    keepPreviousData: true,
    queryFn,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      urlPath,
      Object.keys(paramKeys).length > 0 ? paramKeys : undefined,
    ].filter(Boolean),
    select: (data) => {
      const { pageParams, pages } = data
      const allProducts = (pages || []).reduce(
        (products, currentPage, index) => [
          ...products,
          ...(currentPage.product_result || []).map((product) => ({
            ...product,
            pageIndex: index + 1,
          })),
        ],
        []
      )

      return {
        ...(pages?.length > 0 && pages[pages.length - 1]),
        pageParams,
        placeholders: pages?.[0]?.placeholders || 0,
        product_result: allProducts,
      }
    },
    ...(ssrData && {
      initialData: {
        pageParams: ssrData?.pagination?.current_page_number
          ? [ssrData?.pagination?.current_page_number]
          : undefined,
        pages: [ssrData],
      },
      initialPageParam: ssrData?.pagination?.current_page_number,
    }),
  })

  return {
    ...queryData,
    disabled,
  }
}

useCollection.propTypes = {
  ssrData: PropTypes.object,
  ssrNotFound: PropTypes.bool,
}

export default useCollection
