import { Shimmer } from "@jewlr/storybook/core"
import React from "react"

import {
  defaultBubbleStyle,
  Item,
  Wrapper,
} from "areas/landing/elements/landing-collection-menu"

const CollectionMenuSkeleton = () => (
  <Wrapper>
    {[...Array(9)].map((_, index) => (
      <Item key={`collection-menu-skeleton-${index}`}>
        <Shimmer {...defaultBubbleStyle} />
        <Shimmer height="18px" mt={{ _: 1, widescreen: 2 }} />
      </Item>
    ))}
  </Wrapper>
)

export default CollectionMenuSkeleton
